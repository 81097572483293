import { Button, DatePicker, Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { HttpService } from '../../../service/http.service';
import moment from 'moment';
import IconCell from '../../../components/cell-render/icon-cell';
import { ColDef } from 'ag-grid-community';
import GetUsersDropDown from '../../../components/get_users/get-users.component';
import ImageCell from '../../../components/cell-render/image-cell';
// import ActionCell from './action-cell.ag';

const dateFormat = 'YYYY-MM-DD';
export default function VideosList() {
  const [reload, setReload] = useState(false);
  const currentDate = moment().format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(currentDate);
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [user, setUser] = useState('');
  const [env, setEnv] = useState('dev');

  const [iconColDef] = useState<ColDef[]>([
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   cellRenderer: ActionCell,
    //   width: 130,
    //   pinned: 'right',
    // },
    {
      field: 'face_id',
      headerName: 'Face Selected',
      cellRenderer: ImageCell,
      width: 150,
    },
    {
      field: 'input_video',
      headerName: 'Input',
      cellRenderer: IconCell,
      width: 100,
      pinned: 'right',
    },
    {
      field: 'output_video',
      headerName: 'Ouput',
      cellRenderer: IconCell,
      width: 100,
      pinned: 'right',
    },
  ]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const fetchRecords = () => {
    setReload(true);
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      user: user,
      env: env,
    };
    HttpService.postRequest('get_videos_saas', payload)
      .then(res => {
        setReload(false);
        setRowData(res.data.data.table_data.body_content);
        setColumnDefs([...res.data.data.table_data.header_content, ...iconColDef]);
      })
      .catch(() => setReload(false));
  };
  useEffect(() => {
    if (user == '') return;
    fetchRecords();
  }, [fromDate, toDate, env, user]);

  const onRangeChange = (dates: any, dateStrings: string[]) => {
    if (dates) {
      setFromDate(dateStrings[0]);
      setToDate(dateStrings[1]);
    }
  };

  return (
    <div className='h-full'>
      <div className='flex justify-between items-center'>
        <div className='flex gap-x-2 items-center'>
          <div className='flex flex-col'>
            {/* <label
              htmlFor={'date-picker'}
              className={'text-secondary-80  font-graphik-medium mb-1.5'}>
              Date
            </label> */}
            <RangePicker
              onChange={onRangeChange}
              defaultValue={[dayjs(fromDate, dateFormat), dayjs(toDate, dateFormat)]}
            />
          </div>
          <div className='flex flex-col'>
            {/* <label
              htmlFor={'date-picker'}
              className={'text-secondary-80  font-graphik-medium mb-1.5'}>
              Status Code
            </label> */}
            <GetUsersDropDown type='saas' option={user} setOption={setUser} />
          </div>
          <div className='flex flex-col'>
            {/* <label
              htmlFor={'date-picker'}
              className={'text-secondary-80  font-graphik-medium mb-1.5'}>
              Status Code
            </label> */}
            <Select
              value={env}
              style={{ width: 120 }}
              onChange={(value: string) => {
                setEnv(value);
              }}
              options={[
                {
                  value: 'dev',
                  label: 'Development',
                },
                {
                  value: 'prod',
                  label: 'Production',
                },
              ]}
            />
          </div>
        </div>
        <div>
          <Button
            type='default'
            icon={<ReloadOutlined />}
            onClick={() => fetchRecords()}
            loading={reload}>
            Refresh
          </Button>
        </div>
      </div>
      <div className='mt-3 ag-theme-alpine-dark w-full h-[90%]'>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
            autoHeight: true,
            suppressAutoSize: false,
          }}
          animateRows={true}
          pagination={true}
          rowSelection='multiple'
        />
      </div>
    </div>
  );
}
