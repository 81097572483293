export const EditPencil = () => {
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.7022 15.8299H17.5V17.4966H2.5V13.9611L10.7496 5.71148L14.2851 9.24697L7.7022 15.8299ZM11.9281 4.53296L13.6958 2.76519C14.0213 2.43976 14.5489 2.43976 14.8743 2.76519L17.2314 5.12222C17.5568 5.44765 17.5568 5.97529 17.2314 6.30073L15.4636 8.06849L11.9281 4.53296Z'
          fill='currentColor'
        />
      </svg>
    </>
  );
};

export const TrashFilled = () => {
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.7826 7.24696C15.9487 7.24696 16.0994 7.31946 16.2186 7.44196C16.3296 7.57279 16.3855 7.73529 16.3693 7.90695C16.3693 7.96362 15.9252 13.5802 15.6715 15.9444C15.5127 17.3952 14.5774 18.276 13.1745 18.3002C12.0958 18.3243 11.0414 18.3327 10.0032 18.3327C8.90093 18.3327 7.82302 18.3243 6.77672 18.3002C5.42082 18.2677 4.48474 17.371 4.33399 15.9444C4.07302 13.5719 3.63699 7.96362 3.62889 7.90695C3.62079 7.73529 3.6759 7.57279 3.78774 7.44196C3.89796 7.31946 4.05681 7.24696 4.22377 7.24696H15.7826ZM11.7206 1.66602C12.4573 1.66602 13.1154 2.18018 13.3059 2.9135L13.442 3.52183C13.5522 4.01766 13.9818 4.36849 14.4762 4.36849H16.9059C17.2301 4.36849 17.5 4.63765 17.5 4.98015V5.29681C17.5 5.63098 17.2301 5.90847 16.9059 5.90847H3.09488C2.76988 5.90847 2.5 5.63098 2.5 5.29681V4.98015C2.5 4.63765 2.76988 4.36849 3.09488 4.36849H5.52464C6.01821 4.36849 6.44775 4.01766 6.55879 3.52266L6.68603 2.95434C6.88378 2.18018 7.53458 1.66602 8.27939 1.66602H11.7206Z'
          fill='white'
        />
      </svg>
    </>
  );
};

export const Duplicate = ({ className = 'w-4 h-4' }: { className?: string }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.83317 4.99996V2.49996C5.83317 2.03973 6.20627 1.66663 6.6665 1.66663H16.6665C17.1267 1.66663 17.4998 2.03973 17.4998 2.49996V14.1666C17.4998 14.6269 17.1267 15 16.6665 15H14.1665V17.4992C14.1665 17.9599 13.7916 18.3333 13.3275 18.3333H3.33888C2.87549 18.3333 2.5 17.9628 2.5 17.4992L2.50217 5.83402C2.50225 5.37338 2.8772 4.99996 3.34118 4.99996H5.83317ZM7.49983 4.99996H14.1665V13.3333H15.8332V3.33329H7.49983V4.99996Z'
        fill='white'
      />
    </svg>
  );
};

export const Share = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.3134 14.3929L7.05496 12.07C6.52776 12.5934 5.80172 12.9167 5.00016 12.9167C3.38933 12.9167 2.0835 11.6109 2.0835 10C2.0835 8.38921 3.38933 7.08337 5.00016 7.08337C5.80167 7.08337 6.52767 7.40667 7.05486 7.92998L11.3134 5.60717C11.272 5.41132 11.2502 5.20823 11.2502 5.00004C11.2502 3.38921 12.556 2.08337 14.1668 2.08337C15.7777 2.08337 17.0835 3.38921 17.0835 5.00004C17.0835 6.61087 15.7777 7.91671 14.1668 7.91671C13.3652 7.91671 12.6392 7.59338 12.1121 7.07003L7.85353 9.39279C7.89501 9.58871 7.91683 9.79179 7.91683 10C7.91683 10.2082 7.89502 10.4113 7.85356 10.6071L12.1121 12.93C12.6393 12.4067 13.3653 12.0834 14.1668 12.0834C15.7777 12.0834 17.0835 13.3892 17.0835 15C17.0835 16.6109 15.7777 17.9167 14.1668 17.9167C12.556 17.9167 11.2502 16.6109 11.2502 15C11.2502 14.7919 11.272 14.5887 11.3134 14.3929Z'
        fill='white'
      />
    </svg>
  );
};

export const Resume = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.57673 14.0182H3.58959C3.07947 14.0182 2.59043 13.8156 2.22969 13.4549C1.86911 13.0943 1.6665 12.6052 1.6665 12.0953V7.67255C1.6665 7.16249 1.86911 6.67348 2.22969 6.31278C2.59041 5.95223 3.07947 5.74964 3.58959 5.74964H4.57673V14.0182ZM16.4101 5.77528H8.42289V14.0182H16.4101C16.9202 14.0182 17.4092 13.8156 17.77 13.4549C18.1306 13.0943 18.3332 12.6052 18.3332 12.0953V7.67255C18.3264 7.16692 18.1208 6.68447 17.761 6.32937C17.401 5.97426 16.9158 5.77524 16.4101 5.77524V5.77528ZM7.82692 15.2042C7.64491 15.2042 7.47049 15.1319 7.34186 15.0033C7.21323 14.8747 7.14097 14.7002 7.14097 14.5183V5.30109C7.14097 5.1191 7.21323 4.9447 7.34186 4.81608C7.4705 4.68746 7.64492 4.6152 7.82692 4.6152C8.05586 4.6152 8.26747 4.49302 8.38209 4.29472C8.49656 4.09642 8.49656 3.85204 8.38209 3.65375C8.26747 3.45544 8.05585 3.33326 7.82692 3.33326C7.33355 3.33183 6.85849 3.51997 6.49992 3.85893C6.14149 3.51999 5.66644 3.33183 5.17307 3.33326C4.94399 3.33326 4.73237 3.45544 4.6179 3.65375C4.50343 3.85205 4.50343 4.09642 4.6179 4.29472C4.73237 4.49302 4.94399 4.6152 5.17307 4.6152C5.35493 4.6152 5.52935 4.68746 5.65798 4.81608C5.78661 4.9447 5.85887 5.11911 5.85887 5.30109V14.5183C5.85887 14.7003 5.78661 14.8747 5.65798 15.0033C5.52934 15.1319 5.35492 15.2042 5.17307 15.2042C4.94399 15.2042 4.73237 15.3263 4.6179 15.5246C4.50343 15.7229 4.50343 15.9673 4.6179 16.1656C4.73237 16.3639 4.94399 16.4861 5.17307 16.4861C5.66644 16.4875 6.1415 16.2994 6.49992 15.9604C6.8585 16.2994 7.33355 16.4875 7.82692 16.4861C8.05586 16.4861 8.26747 16.3639 8.38209 16.1656C8.49656 15.9673 8.49656 15.7229 8.38209 15.5246C8.26747 15.3263 8.05585 15.2042 7.82692 15.2042Z'
        fill='white'
      />
    </svg>
  );
};

export const Donwload = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.33317 15.8333H16.6665V10H18.3332V16.6667C18.3332 17.1269 17.9601 17.5 17.4998 17.5H2.49984C2.0396 17.5 1.6665 17.1269 1.6665 16.6667V10H3.33317V15.8333ZM11.6665 7.5H15.8332L9.99984 13.3333L4.1665 7.5H8.33317V2.5H11.6665V7.5Z'
        fill='white'
      />
    </svg>
  );
};
