import {
  FilePlus,
  GalleryIcon,
  Grid,
  ProfileIcon,
  TV,
  Typing,
  UserCheck,
} from '../assets/svg/icons';
import { VideoPlayer } from '../assets/svg/payler-icons';

const icons = {
  file_plus: <FilePlus />,
  file_plus_active: <FilePlus className='text-editor-primary-80' />,
  typing: <Typing />,
  typing_active: <Typing className='text-editor-primary-80' />,
  tv: <TV />,
  user_check: <UserCheck />,
  user_check_active: <UserCheck className='text-editor-primary-80' />,
  tv_active: <TV className='text-editor-primary-80' />,
  // eslint-disable-next-line react/jsx-no-undef
  dashboard: <Grid />,
  projects: <GalleryIcon />,
  profile: <ProfileIcon />,
  video_player: <VideoPlayer />,
};
export function LoadIcon(name) {
  return icons[name];
}
