export const Forward = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.557 6.0619V9.57806C10.4869 8.30688 8.44948 7.05867 6.37847 5.78261C5.65047 5.33405 5.35693 5.36354 5.35693 6.28044V14.601C5.35693 15.5298 5.59239 15.7183 6.3329 15.2615C8.40588 13.9825 10.4735 12.695 12.557 11.4338L12.5571 14.938C12.5571 15.6873 14.6435 15.6873 14.6435 14.938V6.06205C14.6435 5.31274 12.5569 5.31274 12.5569 6.06205L12.557 6.0619Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Backword = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.44303 6.0619V9.57806C9.51314 8.30688 11.5505 7.05867 13.6215 5.78261C14.3495 5.33405 14.6431 5.36354 14.6431 6.28044V14.601C14.6431 15.5298 14.4076 15.7183 13.6671 15.2615C11.5941 13.9825 9.52651 12.695 7.44297 11.4338L7.4429 14.938C7.4429 15.6873 5.3565 15.6873 5.3565 14.938V6.06205C5.3565 5.31274 7.44308 5.31274 7.44308 6.06205L7.44303 6.0619Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Play = ({ className = 'w-5 h-5' }: { className?: string }) => {
  return (
    <svg className={className} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3483 9.47467C11.5339 7.77479 8.74312 6.03638 5.94494 4.31002C4.98237 3.71617 4.67627 3.96121 4.67627 5.16855V15.9854C4.67627 17.1775 5.05778 17.2157 6.00428 16.6326C8.80339 14.9079 11.555 13.2224 14.3567 11.5019C15.6671 10.6972 15.6272 10.2471 14.3484 9.47453L14.3483 9.47467Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const FastForward = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.2115 11.313C14.9815 12.6601 12.7701 14.0375 10.5526 15.4055C9.78981 15.8762 9.54727 15.682 9.54727 14.7253V11.3933C7.36221 12.7147 5.19441 14.0649 3.02098 15.4056C2.25815 15.8762 2.01541 15.682 2.01541 14.7253V6.15389C2.01541 5.20926 2.31787 5.17889 3.06775 5.64106C5.23941 6.97912 7.37518 8.28738 9.54718 9.62106V6.15363C9.54718 5.20901 9.84952 5.17863 10.5995 5.64081C12.8175 7.00743 14.9981 8.34303 17.2181 9.70652C18.2566 10.3442 18.225 10.7009 17.2115 11.3131L17.2115 11.313Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const FastBackward = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.7885 11.313C5.01853 12.6601 7.22993 14.0375 9.44736 15.4055C10.2102 15.8762 10.4527 15.682 10.4527 14.7253V11.3933C12.6378 12.7147 14.8056 14.0649 16.979 15.4056C17.7418 15.8762 17.9846 15.682 17.9846 14.7253V6.15389C17.9846 5.20926 17.6821 5.17889 16.9322 5.64106C14.7606 6.97912 12.6248 8.28738 10.4528 9.62106V6.15363C10.4528 5.20901 10.1505 5.17863 9.40048 5.64081C7.18251 7.00743 5.0019 8.34303 2.7819 9.70652C1.74339 10.3442 1.77499 10.7009 2.78849 11.3131L2.7885 11.313Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const FullScreen = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.4119 8.61782C2.89236 8.61782 2.47073 8.19716 2.47073 7.67665V3.91179C2.47073 3.39236 2.89239 2.9707 3.4119 2.9707H7.17676C7.6963 2.9707 8.11793 3.39236 8.11793 3.91179C8.11793 4.43233 7.69628 4.85296 7.17676 4.85296H4.35322V7.6765C4.35322 8.19705 3.93156 8.61767 3.41205 8.61767L3.4119 8.61782ZM8.1179 17.0884C8.1179 17.6089 7.69625 18.0296 7.17673 18.0296H3.41187C2.89233 18.0296 2.4707 17.6089 2.4707 17.0884V13.3238C2.4707 12.8043 2.89236 12.3826 3.41187 12.3826C3.93139 12.3826 4.35305 12.8043 4.35305 13.3238V16.1472H7.17659C7.69613 16.1472 8.11776 16.5689 8.11776 17.0884L8.1179 17.0884ZM16.5885 12.3827C17.108 12.3827 17.5296 12.8043 17.5296 13.3238V17.0884C17.5296 17.609 17.108 18.0296 16.5885 18.0296H12.8236C12.3041 18.0296 11.8824 17.6089 11.8824 17.0884C11.8824 16.5689 12.3041 16.1472 12.8236 16.1472H15.6472V13.3238C15.6472 12.8043 16.0688 12.3826 16.5883 12.3826L16.5885 12.3827ZM11.8825 3.9121C11.8825 3.39267 12.3041 2.97102 12.8236 2.97102H16.5885C17.108 2.97102 17.5297 3.39267 17.5297 3.9121V7.67696C17.5297 8.1975 17.108 8.61813 16.5885 8.61813C16.069 8.61813 15.6473 8.19747 15.6473 7.67696V4.85342H12.8238C12.3042 4.85342 11.8826 4.43276 11.8826 3.91225L11.8825 3.9121Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Speaker = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.98648 4.54314L5.05905 7.38777H2.01562V13.6121H5.05905L9.9862 16.4573V4.54297L9.98648 4.54314ZM11.9172 11.4977C11.3664 11.4977 10.9197 11.0511 10.9197 10.5003C10.9197 9.94951 11.3663 9.50285 11.9172 9.50285C12.4679 9.50285 12.9146 9.94951 12.9146 10.5003C12.9147 11.0511 12.4681 11.4977 11.9172 11.4977ZM14.6295 6.12771C14.9325 5.81377 15.2358 5.4996 15.5387 5.18563C18.8004 8.56191 18.6961 12.4928 15.8434 15.8148C15.5494 15.5254 15.2556 15.2361 14.9616 14.9469C17.3138 12.2737 17.286 8.72863 14.6295 6.12777V6.12771ZM12.9267 12.9989C13.2952 13.3491 13.5945 13.6156 13.9288 13.9496C15.8467 11.5236 15.6993 9.32705 13.6219 7.15877C13.3387 7.4754 13.0299 7.78365 12.7465 8.10017C14.3939 9.82283 14.2559 11.4522 12.9266 12.999L12.9267 12.9989Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Undo = () => {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.782227 6.81086L7.41166 0.5V3.62343C16.1448 4.06963 19.6508 11.8466 15.4437 16.5H14.4875C15.7624 13.3129 13.0852 10.4443 7.4118 10.0617V13.2489L0.782369 6.81057L0.782227 6.81086Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Redo = () => {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.2178 6.81086L10.5883 0.5V3.62343C1.8552 4.06963 -1.6508 11.8466 2.55634 16.5H3.51249C2.2376 13.3129 4.91483 10.4443 10.5882 10.0617V13.2489L17.2176 6.81057L17.2178 6.81086Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Split = () => {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.4797 12.9333L10.0237 8.51647L15.4797 4.09961C15.6396 3.97628 15.743 3.7937 15.7666 3.59312C15.7901 3.39257 15.7317 3.19112 15.6047 3.0341C15.4778 2.87706 15.2929 2.77796 15.0919 2.75898C14.8909 2.74012 14.6907 2.80307 14.5366 2.93354L8.83179 7.55154L5.43693 4.80154H5.43682C5.85925 4.00868 5.87053 3.06025 5.46706 2.25768C5.06361 1.45522 4.29575 0.898424 3.40758 0.764367C2.51929 0.630438 1.62141 0.935907 0.999208 1.5838C0.377008 2.23168 0.107922 3.14117 0.277665 4.02331C0.447419 4.90534 1.03469 5.65011 1.85289 6.02085C2.67109 6.39151 3.61829 6.34205 4.39341 5.88804L7.63969 8.5165L4.39341 11.145C3.62321 10.6966 2.68335 10.6498 1.87244 11.0193C1.06138 11.3888 0.480151 12.1288 0.313065 13.0041C0.145988 13.8795 0.413956 14.7815 1.03192 15.4237C1.64978 16.0659 2.54084 16.3684 3.42198 16.2351C4.30312 16.102 5.06495 15.5495 5.46541 14.7535C5.86586 13.9574 5.85526 13.0164 5.43684 12.2295L8.83169 9.47946L14.5368 14.0975V14.0976C14.7458 14.2587 15.024 14.2982 15.2696 14.2016C15.5151 14.105 15.6918 13.8865 15.7348 13.6262C15.778 13.366 15.6812 13.1021 15.4799 12.9315L15.4797 12.9333Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const VideoPlayer = ({
  className = 'w-5 h-5 text-standard-white',
}: {
  className?: string;
}) => {
  return (
    <svg className={className} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_583_16671)'>
        <path
          d='M15.0335 9.29167V5.54167C15.0335 5.09964 14.8579 4.67571 14.5454 4.36315C14.2328 4.05059 13.8089 3.875 13.3669 3.875H3.36686C2.92483 3.875 2.50091 4.05059 2.18835 4.36315C1.87579 4.67571 1.7002 5.09964 1.7002 5.54167V15.5417C1.7002 15.9837 1.87579 16.4076 2.18835 16.7202C2.50091 17.0327 2.92483 17.2083 3.36686 17.2083H13.3669C13.8089 17.2083 14.2328 17.0327 14.5454 16.7202C14.8579 16.4076 15.0335 15.9837 15.0335 15.5417V11.7917L18.3669 15.125V5.95833L15.0335 9.29167ZM10.9835 11.2167L10.2085 11.6667L9.06686 12.325L7.14186 13.4417C7.02366 13.5109 6.88921 13.5476 6.75223 13.5479C6.61524 13.5483 6.48063 13.5124 6.36206 13.4438C6.24349 13.3752 6.14521 13.2763 6.07726 13.1574C6.0093 13.0385 5.97409 12.9037 5.9752 12.7667V8.31666C5.97409 8.17968 6.0093 8.04487 6.07726 7.92593C6.14521 7.80699 6.24349 7.70817 6.36206 7.63957C6.48063 7.57097 6.61524 7.53504 6.75223 7.53541C6.88921 7.53577 7.02366 7.57242 7.14186 7.64166H7.21686L9.06686 8.71666L9.98353 9.24167L10.9919 9.82501C11.1073 9.89776 11.2018 9.9994 11.2659 10.1199C11.33 10.2404 11.3615 10.3755 11.3573 10.5119C11.353 10.6483 11.3133 10.7813 11.2419 10.8976C11.1705 11.0139 11.0699 11.1095 10.9502 11.175L10.9835 11.2167Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_583_16671'>
          <rect
            width='16.6667'
            height='13.3333'
            fill='white'
            transform='translate(1.66699 3.83301)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Mic = () => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.94783 13.9923H10.0511C11.885 13.9923 13.3714 12.5059 13.3714 10.6719V5.82041C13.3714 3.98649 11.8851 2.5 10.0511 2.5H9.94783C8.11393 2.5 6.62744 3.9864 6.62744 5.82041V10.6719C6.62744 12.5058 8.11383 13.9923 9.94783 13.9923Z'
        fill='currentColor'
      />
      <path
        d='M9.65561 16.3733V17.8115H6.24587C6.05664 17.8115 5.90179 17.9664 5.90179 18.1556C5.90179 18.3449 6.05662 18.4997 6.24587 18.4997H13.7502C13.9394 18.4997 14.0943 18.3449 14.0943 18.1556C14.0943 17.9664 13.9394 17.8115 13.7502 17.8115H10.3439V16.3733C12.9864 16.2013 15.0818 14.0611 15.0818 11.4564C15.0818 11.2672 14.927 11.1123 14.7377 11.1123C14.5485 11.1123 14.3936 11.2671 14.3936 11.4564C14.3936 13.7927 12.4221 15.6954 9.99989 15.6954C7.57768 15.6954 5.60614 13.7927 5.60614 11.4564C5.60614 11.2672 5.45131 11.1123 5.26206 11.1123C5.0728 11.1123 4.91797 11.2671 4.91797 11.4564C4.91797 14.061 7.01336 16.2012 9.65586 16.3733H9.65561Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const Pause = () => {
  return (
    <svg
      className='mx-auto'
      width='11'
      height='12'
      viewBox='0 0 11 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.89662 11.9908H6.88515C6.73176 11.9908 6.60734 11.8663 6.60734 11.713V0.277805C6.60734 0.124413 6.73186 0 6.88515 0H9.89662C10.05 0 10.1744 0.124515 10.1744 0.277805V11.713C10.1744 11.8666 10.05 11.9909 9.89662 11.9909V11.9908ZM4.11008 11.9908H1.09861C0.945214 11.9908 0.820801 11.8663 0.820801 11.713V0.277805C0.820801 0.124413 0.945316 0 1.09861 0H4.11008C4.26347 0 4.38788 0.124515 4.38788 0.277805V11.713C4.38788 11.8666 4.26337 11.9909 4.11008 11.9909V11.9908Z'
        fill='#ECECEC'
      />
    </svg>
  );
};
