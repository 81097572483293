import { useEffect, useState } from 'react';
import { BreifCase, Email, Person, Phone } from '../../../assets/svg/icons';
import { ProfileCardComponet } from '../../../components/profile-card/profile-card.componet';
import { HttpService } from '../../../service/http.service';
import { ToasterService } from '../../../service/toaster.service';
import ModalComponent from '../../../components/modal/modal.component';
import ButtonComponent from '../../../components/button/button.component';
import { EditPencil } from '../../../assets/svg/recent-projects-icons';
import { useForm } from 'react-hook-form';
import { EditDetails, ResetPassword } from '../../../models/auth.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditDetailsSchema, ResetPasswordSchema } from '../../../schema/auth.schema';
import PasswordInputComponent from '../../../components/input/password-input.component';
import TextInputComponent from '../../../components/input/text-input.component';

export default function Profile() {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const editDetails = useForm<EditDetails>({
    resolver: yupResolver(EditDetailsSchema),
    mode: 'onChange',
  });
  const loadData = () => {
    HttpService.getRequest('get_profile')
      .then(res => {
        setData({ ...res.data.data });
        editDetails.setValue('name', res.data.data.fullname);
        editDetails.setValue('designation', res.data.data.designation);
        editDetails.setValue('mobile', res.data.data.mobile);
        editDetails.setValue('organization_name', res.data.data.organization_name);
        editDetails.setValue('email', res.data.data.email);
      })
      .catch(e => ToasterService('error', e.data.message || 'Failed to load'));
  };
  useEffect(() => {
    loadData();
  }, []);
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    reset,
  } = useForm<ResetPassword>({
    resolver: yupResolver(ResetPasswordSchema),
    mode: 'onChange',
  });
  const onSubmit = (formData: ResetPassword) => {
    try {
      setLoading(true);
      HttpService.postRequest('reset_password', formData)
        .then(response => {
          setLoading(false);
          if (response.data.status === 'success') {
            reset();
            setIsOpen(false);
            ToasterService('success', response.data.message);
          }
        })
        .catch(err => {
          setLoading(false);
          setError('currentPassword', { type: 'custom', message: err.data.message });
        });
    } catch (e: any) {
      ToasterService('error', e.data.message || 'Failed');
      setLoading(false);
    }
  };
  const onEdit = (formData: EditDetails) => {
    try {
      setLoading(true);
      HttpService.postRequest('update_user_info', formData)
        .then(response => {
          setLoading(false);
          if (response.data.status === 'success') {
            setIsEdit(false);
            loadData();
            ToasterService('success', response.data.message);
          }
        })
        .catch(e => {
          ToasterService('error', e.data.message || 'Failed');
          setLoading(false);
        });
    } catch (e: any) {
      ToasterService('error', e.data.message || 'Failed');
      setLoading(false);
    }
  };
  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        title='Change Password'
        close={() => setIsOpen(false)}
        component={
          <form onSubmit={handleSubmit(onSubmit)}>
            <PasswordInputComponent
              theme='dark'
              id='currentPassword'
              label='Current Password'
              placeholder='Current Password'
              register={{ ...register('currentPassword') }}
              error={errors?.currentPassword}
            />
            <PasswordInputComponent
              theme='dark'
              id='newPassword'
              label='New Password'
              placeholder='New Password'
              register={{ ...register('newPassword') }}
              error={errors?.newPassword}
            />
            <PasswordInputComponent
              theme='dark'
              id='confirmPassword'
              label='Confirm Password'
              placeholder='Confirm Password'
              register={{ ...register('confirmPassword') }}
              error={errors?.confirmPassword}
            />
            <ButtonComponent
              width='full'
              text='Save'
              appearance='filled'
              type='submit'
              color='secondary'
              size='sm'
              textColor='dark'
              disable={!isValid || loading}
            />
          </form>
        }
      />
      <ModalComponent
        isOpen={isEdit}
        title='Edit Details'
        close={() => setIsEdit(false)}
        component={
          <form onSubmit={editDetails.handleSubmit(onEdit)}>
            <TextInputComponent
              theme='dark'
              id='name'
              label='Name'
              placeholder='John Doe'
              register={{ ...editDetails.register('name') }}
              error={editDetails.formState.errors?.name}
            />
            <TextInputComponent
              theme='dark'
              id='designation'
              label='Designation'
              placeholder='Assistant Editor'
              register={{ ...editDetails.register('designation') }}
              error={editDetails.formState.errors?.designation}
            />
            <TextInputComponent
              theme='dark'
              id='organization_name'
              label='Organization'
              placeholder='Stealth Inc.'
              register={{ ...editDetails.register('organization_name') }}
              error={editDetails.formState.errors?.organization_name}
            />
            <TextInputComponent
              theme='dark'
              id='email'
              label='Email'
              placeholder='johndoe@gmail.com'
              register={{ ...editDetails.register('email') }}
              error={editDetails.formState.errors?.email}
            />
            <TextInputComponent
              theme='dark'
              id='mobile'
              label='Phone Number'
              placeholder='+91 98765 43210'
              register={{ ...editDetails.register('mobile') }}
              error={editDetails.formState.errors?.mobile}
            />
            <ButtonComponent
              width='full'
              text='Save Changes'
              appearance='filled'
              type='submit'
              color='secondary'
              size='sm'
              textColor='dark'
              disable={!editDetails.formState.isValid || loading}
            />
          </form>
        }
      />
      <div className='h-full pt-[10%] px-5 profile-bg'>
        <div className='flex items-end gap-x-8'>
          <img
            draggable={false}
            src={require('../../../assets/images/person.png')}
            className='h-[200px] w-[200px] '></img>
          <h3 className='text-4xl text-secondary-20 font-graphik-medium font-semibold'>
            {data?.fullname}
          </h3>
          <div>
            <ButtonComponent
              width='fit'
              text='Edit Details'
              appearance='filled'
              type='button'
              color='secondary'
              size='sm'
              icon={<EditPencil />}
              textColor='dark'
              onClick={() => setIsEdit(true)}
            />
          </div>
          <div>
            <ButtonComponent
              width='fit'
              text='Change Password'
              appearance='filled'
              type='button'
              color='secondary'
              size='sm'
              icon={<EditPencil />}
              textColor='dark'
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
        <div className='mt-6 flex flex-wrap gap-5'>
          {data && (
            <>
              {data?.designation && (
                <ProfileCardComponet
                  icon={<BreifCase />}
                  label='Designation'
                  value={data.designation}
                />
              )}
              {data?.email && (
                <ProfileCardComponet icon={<Email />} label='Email' value={data.email} />
              )}
              {data?.role && (
                <ProfileCardComponet icon={<Person />} label='Role' value={data.role} />
              )}
              {data?.mobile && (
                <ProfileCardComponet icon={<Phone />} label='Mobile' value={data.mobile} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
