import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { HttpService } from '../../service/http.service';
import { ToasterService } from '../../service/toaster.service';
interface Props {
  option: string;
  setOption: (option: string) => void;
  type?: 'saas' | 'api';
}
export default function GetUsersDropDown({ option, setOption, type = 'api' }: Props) {
  const [users, setUsers] = useState([]);
  const handleChange = (value: string) => {
    setOption(value);
  };
  useEffect(() => {
    HttpService.getRequest(type === 'api' ? 'get_users' : 'get_saas_users')
      .then(res => {
        setUsers(res.data.data);
        if (res.data.data.length > 0) {
          setOption(res.data.data[0].value);
        }
      })
      .catch(() => {
        ToasterService('error', 'Failed to list user');
      });
  }, []);
  return (
    <Select
      defaultValue={option}
      value={option}
      style={{ width: 120 }}
      onChange={handleChange}
      options={users}
    />
  );
}
