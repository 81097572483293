import { useState } from 'react';
import { CircleExclamation, Eye, EyeCrossed } from '../../assets/svg/icons';
import './input.component.scss';
type OtherPropsModel = any;
type PasswordInputComponentModel = {
  label: string;
  id: string;
  register: any;
  error: any;
  theme: 'dark' | 'light';
} & OtherPropsModel;

export default function PasswordInputComponent({
  label,
  id,
  register,
  error,
  theme = 'light',
  ...otherProps
}: PasswordInputComponentModel) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`flex flex-col mb-5`}>
      <label
        htmlFor={id}
        className={`${
          theme === 'dark' ? 'text-secondary-80' : 'text-surface-75'
        } font-graphik-medium mb-1.5`}>
        {label}
      </label>
      <div className='relative flex'>
        <input
          className={`ng-input-field  text-lg ${
            theme === 'dark' ? 'bg-surface-95 text-secondary-20' : ''
          } mb-1.5 border-solid border-0.5 ${
            error ? 'border-accent-error' : 'border-secondary-80'
          }`}
          type={showPassword ? 'text' : 'password'}
          id={id}
          {...register}
          {...otherProps}
        />
        <span
          className={`absolute ${theme === 'dark' ? 'text-secondary-40' : ''} right-3.5 py-3`}
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <EyeCrossed /> : <Eye />}
        </span>
      </div>
      {error && error.message && (
        <div className='flex items-center text-lg  gap-x-1.5 text-accent-error'>
          <CircleExclamation />
          {error.message}
        </div>
      )}
    </div>
  );
}
