import { toast } from 'react-toastify';
type TosterType = 'success' | 'error' | 'warning' | 'info';
export const ToasterService = (type: TosterType, message: string) => {
  // const icon = CircleExclamation;
  toast[type](` ${message}`, {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'dark',
  });
};
