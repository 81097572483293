import { Navigate } from 'react-router-dom';
import { SessionService } from '../../service/session.service';

const useAuth = (): boolean => {
  const data = SessionService.get_session();
  return !!data.token;
};

export default function LoginGuard({ children }: any) {
  const isAuth = useAuth();
  // Check key is present or not
  if (isAuth) {
    return <Navigate to={'/statistics'} />;
  }
  return children;
}
