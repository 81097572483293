interface Stats {
  icon?: any;
  title: string;
  value: string;
}
export function StatCard({ icon, title, value }: Stats) {
  return (
    <div className='lg:w-1/3 bg-surface-95 opacity-90 hover:opacity-100 rounded-2xl p-8'>
      <div className='flex gap-x-2 items-center text-secondary-60 text-2xl'>
        {icon}
        <p>{title}</p>
      </div>
      <h2 className='text-5xl font-graphik-medium font-semibold text-standard-white'>{value}</h2>
    </div>
  );
}
