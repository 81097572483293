import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/login/login';
import Main from '../pages/main/main';
import Dashboard from '../pages/main/dashboard/dashboard';
import Profile from '../pages/main/profile/profile';
import PrivateRoute from './protected-route/protected-route';
import Statistics from '../pages/main/stats/statistics';
import VideosList from '../pages/main/videos/videos-list';
import VideosListSaas from '../pages/main/saas/videos-saas-list';
import LoginGuard from './login-guard/login-guard';
import SuccessMessage from '../pages/forgot-password/success-message';

export function Router() {
  return (
    <Routes>
      <Route index={true} path='/' element={<Navigate to={'/login'} />} />
      <Route
        index={true}
        path='/login'
        element={
          <LoginGuard>
            <Login />
          </LoginGuard>
        }
      />
      {/* <Route path='/signup' element={<SignUp />} /> */}
      <Route index={true} path='/success' element={<SuccessMessage />} />
      <Route
        element={
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        }>
        <Route index={true} path='/dashboard' element={<Dashboard />} />
        <Route index={true} path='/profile' element={<Profile />} />
        <Route index={true} path='/statistics' element={<Statistics />} />
        <Route index={true} path='/videos' element={<VideosList />} />
        <Route index={true} path='/videos-saas' element={<VideosListSaas />} />
      </Route>
      {/* </Route> */}
    </Routes>
  );
}
