import { Endpoints } from './models/requests.model';
export class Config {
  /**
   * Returns the Base URL
   */
  private static get BASE_URL() {
    return '/neuralgarage/api/';
  }

  /**
   * Returns the Steaming Base URL
   */
  private static get STREAMING_URL() {
    return '/neuralgarage/stream/';
  }

  /**
   * Returns the SESSION NAME
   */
  public static get SESSION_NAME() {
    return process.env.REACT_APP_SESSION_NAME || 'user';
  }

  /**
   * Returns the SESSION NAME
   */
  public static get AES_KEY() {
    return process.env.REACT_APP_AES_KEY || ':tMW%Qf@4q';
  }

  public static get REACT_APP_EXECUTION(): any {
    return process.env.REACT_APP_EXECUTION;
  }

  public static get REACT_APP_QA(): any {
    return process.env.REACT_APP_QA;
  }

  public static get REACT_APP_DEVELOPER(): any {
    return process.env.REACT_APP_DEVELOPER;
  }

  public static get REACT_APP_MANAGER(): any {
    return process.env.REACT_APP_MANAGER;
  }

  public static get REACT_APP_ADMIN(): any {
    return process.env.REACT_APP_ADMIN;
  }

  public static get REACT_APP_SUPER_ADMIN(): any {
    return process.env.REACT_APP_SUPER_ADMIN;
  }

  /**
   * Endpoints
   */
  public static API: Endpoints = {
    /* Authorization Module */
    login: Config.BASE_URL + 'login',

    get_users: Config.BASE_URL + 'users',
    get_saas_users: Config.BASE_URL + 'users_saas',
    cards: Config.BASE_URL + 'cards',
    get_videos: Config.BASE_URL + 'videos',
    get_videos_saas: Config.BASE_URL + 'videos_saas',
    // Old
    signup: Config.BASE_URL + 'register',
    reset_password: Config.BASE_URL + 'reset_password',
    logout: Config.BASE_URL + 'logout',
    forgot_password: Config.BASE_URL + 'forgot_password',
    forgot_reset: Config.BASE_URL + 'forgot_and_reset_password',
    in_video_streaming: Config.STREAMING_URL + 'video?t=in&q=',
    out_video_streaming: Config.STREAMING_URL + 'video?t=out&q=',
    /**Home Module*/
    get_user_details: Config.BASE_URL + 'home/get_user_details',
    create_project: Config.BASE_URL + 'home/create_project',
    get_statistics: Config.BASE_URL + 'home/get_statistics',
    list_recent_projects: Config.BASE_URL + 'home/list_recent_projects',
    list_projects: Config.BASE_URL + 'home/list_projects',
    delete_project: Config.BASE_URL + 'home/delete_project',
    get_file: Config.BASE_URL + 'home/get_file',
    load_menu: Config.BASE_URL + 'home/load_menu',

    /**Profile */
    get_profile: Config.BASE_URL + 'home/get_profile',
    update_user_info: Config.BASE_URL + 'user/update_user_info',
    /**Editor Module */
    project_details: Config.BASE_URL + 'editor/project_details',
    upload_video: Config.BASE_URL + 'editor/upload_video',
    upload_audio: Config.BASE_URL + 'editor/upload_audio',
    list_media: Config.BASE_URL + 'editor/list_media/',
    delete_media: Config.BASE_URL + 'editor/delete_media',
    audio_streaming: Config.BASE_URL + 'editor/audio_streaming/',

    in_audio_streaming: Config.STREAMING_URL + 'audio?q=',

    visual_dub: Config.BASE_URL + 'editor/visual_dub',
    load_outputs: Config.BASE_URL + 'editor/get_outputs/',
    thumb: Config.BASE_URL + 'editor/thumb',
    list_voices: Config.BASE_URL + 'editor/list_voices',
    text_to_speech: Config.BASE_URL + 'editor/text_to_speech',
    get_status: Config.BASE_URL + 'editor/get_status/',
    get_dubbed_versions: Config.BASE_URL + 'editor/get_dubbed_versions/',
    update_name: Config.BASE_URL + 'editor/update_name',
    get_latest_dubbed_version: Config.BASE_URL + 'editor/get_latest_dubbed_version/',
    get_progress: Config.BASE_URL + 'editor/get_progress/',
    get_details: Config.BASE_URL + 'editor/get_details/',
    clone_media: Config.BASE_URL + 'editor/clone_media',
    pre_process: Config.BASE_URL + 'editor/pre_process',
    pre_process_status: Config.BASE_URL + 'editor/pre_process_status',
    get_media: Config.BASE_URL + 'editor/get_media/',

    /**
     * New Version APIS
     */
    get_scenes: Config.BASE_URL + 'editor/get_scenes?',
    pre_process_clip: Config.BASE_URL + 'editor/pre_process_clip',
    visual_dub_clip: Config.BASE_URL + 'editor/visual_dub_clip',
    get_visualdubbed_clips: Config.BASE_URL + 'editor/get_visual_dubbed_clips?',
    export_final_clip: Config.BASE_URL + 'editor/export_final',
    get_clip_status: Config.BASE_URL + 'editor/check_clip_status?',
    update_timeline: Config.BASE_URL + 'editor/update_timeline',
    get_timeline_data: Config.BASE_URL + 'editor/get_timeline_data?',
    cut_clip: Config.BASE_URL + 'editor/cut_clip',
    report_issue: Config.BASE_URL + 'home/report_issue',
    extract_audio: Config.BASE_URL + 'editor/extract_audio',
    create_media: Config.BASE_URL + 'editor/create_media',
    // Stats

    get_stats_file: Config.BASE_URL + 'stats/get_file',
    get_stats: Config.BASE_URL + 'stats/get_stats',
  };
}
