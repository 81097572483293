import { Config } from '../config';
import jwtDecode from 'jwt-decode';
import { AES, enc } from 'crypto-js';

export class SessionService {
  static set_session(data: any) {
    const encryptedData = AES.encrypt(JSON.stringify(data), Config.AES_KEY);
    localStorage.setItem(Config.SESSION_NAME, encryptedData);
  }

  static get_session() {
    const sessionData = localStorage.getItem(Config.SESSION_NAME);
    if (!sessionData) return {};
    const decryptedData = AES.decrypt(sessionData, Config.AES_KEY);
    return JSON.parse(decryptedData.toString(enc.Utf8));
  }

  static get_decoded_data(token: string) {
    return jwtDecode(token);
  }

  static get_decoded_token() {
    const data: any = this.get_session();
    if (JSON.stringify(data) == '{}') return false;
    const sessionData: any = this.get_decoded_data(data.token);
    if (data.project_id) sessionData.project_id = data.project_id;
    return sessionData;
  }
}
