import TextInputComponent from '../../components/input/text-input.component';
import AuthSideBanner from '../../layouts/auth-side-banner/auth-side-banner.layout';
import { loginSchema } from '../../schema/auth.schema';
import { LoginFormFields } from '../../models/auth.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PasswordInputComponent from '../../components/input/password-input.component';
import ButtonComponent from '../../components/button/button.component';
import { Link, useNavigate } from 'react-router-dom';
import { HttpService } from '../../service/http.service';
import { useState } from 'react';
import { SessionService } from '../../service/session.service';
import { ToasterService } from '../../service/toaster.service';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setError,
  } = useForm<LoginFormFields>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });
  const onSubmit = (data: LoginFormFields) => {
    if (!isValid) return;
    setLoading(true);
    HttpService.postRequest('login', data)
      .then(response => {
        setLoading(false);
        if (response.data.status === 'success') {
          SessionService.set_session(response.data.data);
          navigate('/statistics');
        }
      })
      .catch(err => {
        setLoading(false);
        if (err.status == 400) {
          ToasterService('error', 'Please Contact the team');
        } else {
          setError('password', { type: 'custom', message: err.data.message });
        }
      });
  };
  return (
    <div className='h-screen flex'>
      <AuthSideBanner />
      <div className='m-auto  py-10 px-16'>
        <h3 className='text-3xl font-graphik-medium mb-10'>Login</h3>
        <form onSubmit={handleSubmit(onSubmit)} className='w-[420px]'>
          <TextInputComponent
            id='email'
            label='Email'
            placeholder='johndoe@gmail.com'
            type='email'
            register={{ ...register('email') }}
            error={errors?.email}
          />
          <PasswordInputComponent
            id='password'
            label='Password'
            placeholder='Password'
            register={{ ...register('password') }}
            error={errors?.password}
          />
          <div className='flex justify-end mb-10 font-graphik-medium'>
            {/*<CheckboxComponent id='remember' label='Remember Me' />*/}
            <Link to={'/forgot-password'} className='underline cursor-pointer text-base'>
              Forgot Password?
            </Link>
          </div>
          <ButtonComponent
            text='Login'
            type='submit'
            textColor='white'
            width='full'
            disable={loading || !isValid}
          />
        </form>
        {/* <AuthFooterContent question='Don’t have an account?' to='/signup' linkText='Signup' /> */}
      </div>
    </div>
  );
}
