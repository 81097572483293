import classNames from 'classnames';
import { CircleExclamation } from '../../assets/svg/icons';
import './input.component.scss';
type OtherPropsModel = any;
type TextInputComponentModel = {
  label?: string;
  className?: string;
  id: string;
  register: any;
  error: any;
  theme: 'dark' | 'light';
  marginClass: string;
} & OtherPropsModel;
export default function TextInputComponent({
  label,
  id,
  register,
  error,
  theme = 'light',
  className = 'mb-1.5',
  marginClass = 'mb-5',
  ...otherProps
}: TextInputComponentModel) {
  const classnames = classNames('ng-input-field  text-lg border-solid border-0.5 ' + className, {
    'bg-surface-95 text-secondary-20': theme === 'dark',
    'border-accent-error': error,
    'border-secondary-80': !error,
  });
  return (
    <div className={`flex flex-col ${!error ? marginClass : ''} `}>
      {label && (
        <label
          htmlFor={id}
          className={`${
            theme === 'dark' ? 'text-secondary-80' : 'text-surface-75'
          } font-graphik-medium mb-1.5`}>
          {label}
        </label>
      )}
      <input className={classnames} id={id} {...register} {...otherProps} />
      {error && error.message && (
        <div className='flex items-center text-lg  gap-x-1.5 text-accent-error'>
          <CircleExclamation />
          {error.message}
        </div>
      )}
    </div>
  );
}
