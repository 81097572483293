import './auth-side-banner.layout.scss';
export default function AuthSideBanner() {
  return (
    <div className='relative lg:flex w-[46.25rem] hidden auth-banner'>
      <div className='absolute top-[80px] left-[60px] flex min-h-[55px] items-center gap-x-3'>
        <div className='main-logo'></div>
        <div className='app-logo'></div>
      </div>
      <div className='absolute bottom-28 left-[60px] text-standard-white text-3xl w-[28rem]'>
        Experience the Future of Audio & Video with our AI-Powered Platform
      </div>
    </div>
  );
}
