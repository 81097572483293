import { DatePicker, Button } from 'antd';
const { RangePicker } = DatePicker;
import { ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { StatCard } from '../../../components/stat-card/stat-card';
import { VideoPlayer } from '../../../assets/svg/payler-icons';
import { Clock, PieChart } from '../../../assets/svg/icons';
import { HttpService } from '../../../service/http.service';
import { ToasterService } from '../../../service/toaster.service';
import GetUsersDropDown from '../../../components/get_users/get-users.component';
const dateFormat = 'YYYY-MM-DD';

export default function Statistics() {
  const [stats, setStats] = useState([
    {
      value: '0s',
      label: 'Time Rendered',
      icon: '',
    },
    {
      value: '0s',
      label: 'Average Duration',
      icon: '',
    },
  ]);
  const [user, setUser] = useState('');
  const currentDate = moment().format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(currentDate);
  const onRangeChange = (dates: any, dateStrings: string[]) => {
    if (dates) {
      setFromDate(dateStrings[0]);
      setToDate(dateStrings[1]);
    }
  };
  const fetchDetails = () => {
    try {
      setLoading(true);
      HttpService.postRequest('cards', {
        from_date: fromDate,
        to_date: toDate,
        user: user,
      })
        .then(res => {
          setLoading(false);
          setStats([...res.data.data]);
        })
        .catch(e => {
          setLoading(false);
          ToasterService('error', e.data.message || 'Unable to fetch details');
        });
    } catch (e) {
      setLoading(false);
    }
  };
  const renderIcon = (icon: string) => {
    let displayIcon: any = null;
    switch (icon) {
      case 'videoplayer':
        displayIcon = <VideoPlayer className='w-[32px] h-[32px]' />;
        break;
      case 'clock':
        displayIcon = <Clock className='w-[32px] h-[32px]' />;
        break;
      case 'piechart':
        displayIcon = <PieChart />;
        break;
    }
    return displayIcon;
  };
  useEffect(() => {
    if (user == '') return;
    fetchDetails();
  }, [user]);
  return (
    <div className=''>
      <div className='flex justify-between items-center gap-x-2 mb-5'>
        <div className='flex gap-2'>
          <RangePicker
            onChange={onRangeChange}
            defaultValue={[dayjs(fromDate, dateFormat), dayjs(toDate, dateFormat)]}
          />
          <GetUsersDropDown option={user} setOption={setUser} />
        </div>
        <Button type='default' icon={<ReloadOutlined />} onClick={fetchDetails} loading={loading}>
          Refresh
        </Button>
      </div>
      <div className='flex flex-col lg:flex-row overflow-x-hidden gap-y-3 lg:gap-x-3'>
        {stats &&
          stats.map((ele: { value: string; label: string; icon: any }, idx) => (
            <StatCard key={idx} title={ele.label} value={ele.value} icon={renderIcon(ele.icon)} />
          ))}
      </div>
    </div>
  );
}
