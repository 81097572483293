import { Navigate } from 'react-router-dom';
import { SessionService } from '../../service/session.service';

const useAuth = (): boolean => {
  const data = SessionService.get_session();
  if (!data.token) localStorage.clear();
  return !!data.token;
};

export default function PrivateRoute({ children }: any) {
  const isAuth = useAuth();
  // Check key is present or not

  const loginReturn = <Navigate to={'/login'} />;
  if (!isAuth) return loginReturn;

  // Checking the required data is available in the session
  const data: any = SessionService.get_decoded_token();
  if (JSON.stringify(data) === '{}') return loginReturn;

  return children;
}
