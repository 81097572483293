import { useEffect, useState } from 'react';
import { Clock, PieChart, Reload } from '../../../assets/svg/icons';
import { VideoPlayer } from '../../../assets/svg/payler-icons';
import { StatCard } from '../../../components/stat-card/stat-card';
import { HttpService } from '../../../service/http.service';
import { ToasterService } from '../../../service/toaster.service';
import {
  // Donwload,
  // Duplicate,
  EditPencil,
  Resume,
  // Share,
  TrashFilled,
} from '../../../assets/svg/recent-projects-icons';
import { useNavigate } from 'react-router-dom';
import TextInputComponent from '../../../components/input/text-input.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditProject } from '../../../models/common.model';
import { EditProjectNameSchema } from '../../../schema/common.schema';
import { useForm } from 'react-hook-form';
import ModalComponent from '../../../components/modal/modal.component';
import ButtonComponent from '../../../components/button/button.component';
import { Tooltip } from 'antd';
import { useSetRecoilState } from 'recoil';
import { RecentProject } from '../../../recoil/atoms';

interface StatsModel {
  title: string;
  value: string;
  icon: string;
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [editNameIndex, setEditNameIndex] = useState<number | undefined>();
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>();
  const [recent, setRecent] = useState<any[]>([]);
  const [nameLoading, setNameLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const setRecentProject = useSetRecoilState(RecentProject);
  const [stats, setStats] = useState<StatsModel[]>([
    {
      title: 'Videos in total',
      value: '0',
      icon: 'videoplayer',
    },
    {
      title: 'Time rendered',
      value: '0h',
      icon: 'clock',
    },
    // {
    //   title: 'Remaining Quota',
    //   value: '0%',
    //   icon: 'piechart',
    // },
  ]);
  const fetchData = () => {
    HttpService.getRequest('get_statistics')
      .then(res => {
        setStats(res.data.data);
      })
      .catch(e => ToasterService('error', e.data.message));
  };
  const fetchRecent = () => {
    HttpService.getRequest('list_recent_projects')
      .then(res => {
        setRecent(res.data.data);
        if (res.data.data.length) setRecentProject(res.data.data[0].project_id);
      })
      .catch(e => ToasterService('error', e.data.message));
  };
  useEffect(() => {
    fetchData();
    fetchRecent();
  }, []);
  const renderIcon = (icon: string) => {
    let displayIcon: any = null;
    switch (icon) {
      case 'videoplayer':
        displayIcon = <VideoPlayer className='w-[32px] h-[32px]' />;
        break;
      case 'clock':
        displayIcon = <Clock className='w-[32px] h-[32px]' />;
        break;
      case 'piechart':
        displayIcon = <PieChart />;
        break;
    }
    return displayIcon;
  };

  const {
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm<EditProject>({
    resolver: yupResolver(EditProjectNameSchema),
    mode: 'onChange',
  });

  const onSubmit = (data: EditProject, project_id: string, idx: number) => {
    try {
      setEditNameIndex(undefined);
      if (data.project_name !== recent[idx].project_name && isValid) {
        const payload = {
          project_name: data.project_name,
          project_id,
        };
        setNameLoading(true);
        HttpService.postRequest('update_name', payload)
          .then(() => {
            recent[idx].project_name = data.project_name;
            setNameLoading(false);
          })
          .catch(e => {
            setNameLoading(false);
            ToasterService('error', e.data.message || 'Failed to update project name');
          });
      }
    } catch (e) {
      setNameLoading(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setDeleteIndex(undefined);
  };
  const deleteProject = () => {
    if (deleteIndex || deleteIndex == 0) {
      closeModal();
      HttpService.postRequest('delete_project', {
        project_id: recent[deleteIndex].project_id,
      })
        .then(() => {
          fetchRecent();
        })
        .catch(e => ToasterService('error', e?.data?.message || 'Failed to delete'));
    }
  };
  const openConfirmation = (id: number) => {
    setIsOpen(true);
    setDeleteIndex(id);
  };
  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        close={closeModal}
        component={
          <>
            <div className='p-10 flex flex-col items-center gap-y-8'>
              <svg
                width='48'
                height='48'
                viewBox='0 0 48 48'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M3.95633 10.2191H7.96519V40.2849C7.96519 42.0127 8.65133 43.6694 9.87293 44.8911C11.0945 46.1127 12.7517 46.7992 14.4794 46.7992H34.5233C36.2507 46.7992 37.9079 46.1127 39.1295 44.8911C40.3511 43.6695 41.0373 42.0127 41.0373 40.2849V10.2191H44.044C44.5813 10.2191 45.0773 9.93246 45.3461 9.46758C45.6145 9.00235 45.6145 8.42915 45.3461 7.9642C45.0773 7.49897 44.5813 7.21235 44.044 7.21235H33.521V6.71111V6.71146C33.521 5.24933 32.9404 3.84733 31.9069 2.81373C30.8729 1.78013 29.4709 1.19922 28.0091 1.19922H20.9936C19.5318 1.19922 18.1298 1.78015 17.0962 2.81373C16.0623 3.84733 15.4817 5.24933 15.4817 6.71146V7.2127L3.9565 7.21235C3.41927 7.21235 2.92326 7.49897 2.65446 7.9642C2.38601 8.42908 2.38601 9.00236 2.65446 9.46758C2.92325 9.93246 3.41925 10.2191 3.9565 10.2191H3.95633ZM38.0309 40.2849C38.0309 41.2153 37.6614 42.1073 37.0036 42.7652C36.3458 43.423 35.4535 43.7925 34.5233 43.7925H14.4794C13.549 43.7925 12.657 43.423 11.9991 42.7652C11.3413 42.1074 10.9715 41.2154 10.9715 40.2849V10.2191H38.0307L38.0309 40.2849ZM18.4881 6.7114H18.4878C18.4878 6.04693 18.752 5.40972 19.2218 4.93958C19.6916 4.4698 20.3288 4.2059 20.9933 4.2059H28.0088C28.6733 4.2059 29.3105 4.4698 29.7803 4.93958C30.2504 5.40971 30.5143 6.04692 30.5143 6.7114V7.21264L18.488 7.2123L18.4881 6.7114Z'
                  fill='#ECECEC'
                />
                <path
                  d='M24.5014 18.7402C24.1026 18.7402 23.7202 18.8986 23.4381 19.1807C23.1564 19.4624 22.998 19.8448 22.998 20.2436V38.2831C22.998 38.82 23.2847 39.3163 23.7495 39.5848C24.2148 39.8532 24.7877 39.8532 25.2529 39.5848C25.7181 39.3163 26.0044 38.82 26.0044 38.2831V20.2436C26.0044 19.8448 25.8461 19.4624 25.5644 19.1807C25.2823 18.8986 24.8999 18.7402 24.5014 18.7402Z'
                  fill='#ECECEC'
                />
                <path
                  d='M30.0117 20.2436V38.2831C30.0117 38.82 30.2983 39.3163 30.7636 39.5848C31.2285 39.8532 31.8017 39.8532 32.2666 39.5848C32.7318 39.3163 33.0184 38.82 33.0184 38.2831V20.2436C33.0184 19.7064 32.7318 19.2104 32.2666 18.9416C31.8017 18.6731 31.2284 18.6731 30.7636 18.9416C30.2983 19.2104 30.0117 19.7064 30.0117 20.2436Z'
                  fill='#ECECEC'
                />
                <path
                  d='M17.4848 18.7402C17.0863 18.7402 16.7039 18.8986 16.4219 19.1807C16.1401 19.4624 15.9814 19.8448 15.9814 20.2436V38.2831C15.9814 38.82 16.2681 39.3163 16.7333 39.5848C17.1982 39.8532 17.7715 39.8532 18.2367 39.5848C18.7016 39.3163 18.9882 38.82 18.9882 38.2831V20.2436C18.9882 19.8448 18.8298 19.4624 18.5478 19.1807C18.266 18.8986 17.8836 18.7402 17.4848 18.7402Z'
                  fill='#ECECEC'
                />
              </svg>
              <p className='text-center text-secondary-20'>
                Are you sure you want to delete the “
                {deleteIndex || deleteIndex == 0 ? recent[deleteIndex]?.project_name : ''}”?
              </p>
              <div className='flex w-full gap-x-2'>
                <ButtonComponent
                  width='full'
                  text='Canel'
                  textColor='white'
                  color='secondary'
                  appearance='outline'
                  type='button'
                  onClick={closeModal}
                />
                <ButtonComponent
                  width='full'
                  text='Delete'
                  textColor='dark'
                  color='secondary'
                  appearance='filled'
                  type='button'
                  onClick={deleteProject}
                />
              </div>
            </div>
          </>
        }
      />

      <div className='flex flex-col lg:flex-row gap-5 ' id='dash-statistics'>
        {stats &&
          stats.map((ele: StatsModel, idx: number) => (
            <StatCard key={idx} title={ele.title} value={ele.value} icon={renderIcon(ele.icon)} />
          ))}
      </div>
      <h4 className='text-3xl font-graphik-medium text-secondary-80 my-6'>Recent Projects</h4>
      {/* Project Card */}
      <div className='h-[calc(100%_-_255px)] overflow-auto' id='recent-project-list'>
        {recent &&
          recent.map((ele: any, idx: number) => (
            <div
              key={idx}
              className=' mb-1 bg-surface-95 flex p-2.5 gap-x-2 items-center rounded-2xl opacity-90 hover:opacity-100'>
              {ele.thumbnail ? (
                <img src='/test/file.jpeg' className='w-25 rounded-2xl mx-2' alt='' />
              ) : null}
              <div className='flex-1'>
                {editNameIndex === idx ? (
                  <form onSubmit={handleSubmit(data => onSubmit(data, ele.project_id, idx))}>
                    <TextInputComponent
                      autoFocus
                      onBlur={handleSubmit(data => onSubmit(data, ele.project_id, idx))}
                      theme='dark'
                      id='projectName'
                      placeholder='Project Name'
                      register={{ ...register('project_name') }}
                      error={errors?.project_name}
                      className={'!w-[20%]'}
                    />
                  </form>
                ) : (
                  <h3 className='text-2xl text-secondary-20'>{ele.project_name}</h3>
                )}
                <div className='flex mt-2 text-base gap-x-5'>
                  {ele?.details.map((details: any, index: number) => (
                    <p key={index}>
                      <span className='text-secondary-80'>{details.label}:</span>
                      <span className='text-secondary-20'>&nbsp;{details.value}</span>
                    </p>
                  ))}
                </div>
              </div>
              <div className='flex flex-2 gap-x-3 recent-menus'>
                {nameLoading && editNameIndex === idx && (
                  <div className='w-9 h-9 bg-surface-85 rounded-2xl p-2 text-standard-white'>
                    <Reload />
                  </div>
                )}
                <Tooltip placement='top' title={'Edit Name'}>
                  <div
                    className='w-9 h-9 bg-surface-85 rounded-2xl p-2 text-secondary-20'
                    onClick={() => {
                      setValue('project_name', ele.project_name);
                      setEditNameIndex(idx);
                    }}>
                    <EditPencil />
                  </div>
                </Tooltip>
                <Tooltip placement='top' title={'Open Editor'}>
                  <div
                    className='w-9 h-9 bg-surface-85 rounded-2xl p-2'
                    onClick={() => {
                      let state: any = undefined;
                      if (
                        ele.project_name == 'Demo Project' &&
                        ele?.details.length &&
                        ele?.details[2].value == 0
                      ) {
                        state = { enable_tour: true, step: 'editor-intro' };
                      }
                      if (
                        ele.project_name == 'Demo Project' &&
                        ele?.details.length &&
                        ele?.details[2].value > 0
                      ) {
                        state = { enable_tour: true, step: 'after-dubbing' };
                      }
                      navigate('/editor/' + ele.project_id, { state });
                    }}>
                    <Resume />
                  </div>
                </Tooltip>
                <Tooltip placement='top' title={'Delete'}>
                  <div
                    className='w-9 h-9 bg-surface-85 rounded-2xl p-2'
                    onClick={() => openConfirmation(idx)}>
                    <TrashFilled />
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
