import { NavLink } from 'react-router-dom';
import { LoadIcon } from '../../helpers/load-icon.helper';
import tabs from '../../constants/JSONS/side-nav.json';
import { useEffect, useState } from 'react';
import { SessionService } from '../../service/session.service';
import classNames from 'classnames';
export default function SideNav() {
  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    const data = SessionService.get_decoded_token();
    setUserData(data);
  }, []);
  return (
    <div className='h-full min-w-[278px] bg-surface-95 '>
      <div className='flex items-center gap-x-2 mt-5 mb-9 h-fit px-8'>
        <img src='/svg/main-logo.svg' />
        <img src='/svg/visualdub.svg' />
      </div>
      {tabs &&
        tabs.map(
          (
            ele: { icon: any; to: string; label: string; key: string; roles: number[] },
            idx: number
          ) => (
            <div key={idx}>
              {userData && ele.roles.includes(userData.role_id) ? (
                <NavLink
                  key={idx}
                  to={ele.to}
                  className={({ isActive }) =>
                    classNames(
                      'flex gap-x-2 font-graphik-medium px-8 py-3.5 opacity-90 hover:opacity-100',
                      {
                        'text-editor-primary-80 bg-surface-100': isActive,
                        'text-secondary-20': !isActive,
                      }
                    )
                  }>
                  {LoadIcon(ele.icon)}
                  <h3 className='text-xl'>{ele.label}</h3>
                </NavLink>
              ) : null}
            </div>
          )
        )}
    </div>
  );
}
