interface ProfileCardModel {
  icon: any;
  label: string;
  value: string;
}
export function ProfileCardComponet({ icon, label, value }: ProfileCardModel) {
  return (
    <div className='py-6 px-8 md:basis-1/3 bg-surface-95 rounded-4xl '>
      <div className='flex  gap-x-2 text-secondary-80 text-xl font-graphik-medium mb-1'>
        {icon}
        <p>{label}</p>
      </div>
      <p className='text-secondary-20 text-3xl'>{value}</p>
    </div>
  );
}
