import { Rings } from 'react-loader-spinner';
import './App.scss';
import { Router } from './routers/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilValue } from 'recoil';
import { Loader } from './recoil/atoms';
import { ConfigProvider, theme } from 'antd';
import pkg from '../package.json';
import CacheBuster from 'react-cache-buster';

// import 'dotenv/config';
function App() {
  const isLoading = useRecoilValue(Loader);
  const { darkAlgorithm } = theme;
  const isProduction = process.env.NODE_ENV === 'production';
  const { version } = pkg;
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={'.'}>
      <ConfigProvider
        theme={{
          algorithm: darkAlgorithm,
          token: {
            fontFamily: 'GraphikRegular',
            fontWeightStrong: 500,
          },
        }}>
        <div className='App'>
          <Router />
          <Rings
            height='150'
            width='150'
            color='#33A9FF'
            radius='6'
            wrapperStyle={{}}
            wrapperClass='spinnerClass'
            visible={isLoading}
            ariaLabel='rings-loading'
          />
          <ToastContainer />
        </div>
      </ConfigProvider>
    </CacheBuster>
  );
}

export default App;
