import * as yup from 'yup';
import {
  ForgotFormModel,
  LoginFormFields,
  SteponeFormFields,
  StepTwoFormFields,
  StepThreeFormFields,
  ResetPassword,
  EditDetails,
  ForgotResetFormModel,
} from '../models/auth.model';
const messages = {
  required: 'Required',
  email: 'Invalid Email',
  password_match: "Passwords don't match",
  min_one: 'Select At least one use',
  password_invalid:
    'Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
  phone_invalid: 'Invalid Mobile Number',
};

export const loginSchema: yup.ObjectSchema<LoginFormFields> = yup.object().shape({
  email: yup.string().required(messages.required).trim(),
  password: yup.string().required(messages.required).trim(),
});

export const StepOneSchema: yup.ObjectSchema<SteponeFormFields> = yup.object().shape({
  full_name: yup.string().required(messages.required).trim('Empty name not allowed'),
  mobile: yup
    .string()
    .trim()
    .required(messages.required)
    .max(10, `Mobile number shouldn't exceed 10`)
    .min(10, 'Mobile number should contain atleast')
    .matches(/^[0-9]+$/, messages.phone_invalid),
  organization_name: yup.string().required(messages.required),
  email: yup
    .string()
    .trim()
    .email()
    .required(messages.required)
    .matches(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/, messages.email),
});

export const StepTwoSchema: yup.ObjectSchema<StepTwoFormFields> = yup.object().shape({
  username: yup.string().required(messages.required).trim(),
  password: yup
    .string()
    .trim()
    .required(messages.required)
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%_^&*])[a-zA-Z0-9!@#$%_^&*]{8,30}$/,
      messages.password_invalid
    ),
  confirm_password: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), undefined], messages.password_match)
    .required(messages.required),
});
export const StepThreeSchema: yup.ObjectSchema<StepThreeFormFields> = yup.object().shape({
  current_role: yup.mixed().required(messages.required),
  industry: yup.mixed().required(messages.required),
});
export const forgotSchema: yup.ObjectSchema<ForgotFormModel> = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email()
    .required(messages.required)
    .matches(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/, messages.email),
});

export const ResetPasswordSchema: yup.ObjectSchema<ResetPassword> = yup.object().shape({
  currentPassword: yup.string().required(messages.required).trim(),
  newPassword: yup
    .string()
    .trim()
    .required(messages.required)
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%_^&*])[a-zA-Z0-9!@#$%_^&*]{8,30}$/,
      messages.password_invalid
    ),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), undefined], messages.password_match)
    .required(messages.required),
});

export const EditDetailsSchema: yup.ObjectSchema<EditDetails> = yup.object().shape({
  name: yup
    .string()
    .required('Full name is required')
    .trim()
    .min(2, 'Full name must be at least 2 characters')
    .max(50, 'Full name must be less than 50 characters'),

  designation: yup
    .string()
    .trim()
    .required('Designation is required')
    .max(100, 'Designation must be less than 100 characters'),

  organization_name: yup
    .string()
    .trim()
    .required('Organization Name is required')
    .max(100, 'Organization Name must be less than 100 characters'),

  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required')
    .max(100, 'Email must be less than 100 characters'),

  mobile: yup
    .string()
    .trim()
    .matches(/^[+]?[0-9]{10,15}$/, 'Invalid phone number')
    .required('Phone number is required'),
});

export const ForgetResetSchema: yup.ObjectSchema<ForgotResetFormModel> = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .required(messages.required)
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%_^&*])[a-zA-Z0-9!@#$%_^&*]{8,30}$/,
      messages.password_invalid
    ),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), undefined], messages.password_match)
    .required(messages.required),
});
