import classNames from 'classnames';
import './button.component.scss';
type ButtonComponentModel = {
  id?: string;
  text: string;
  type?: 'submit' | 'button';
  size?: 'sm' | 'md';
  width?: 'fit' | 'full';
  disable?: boolean;
  color?: 'primary' | 'secondary' | 'editor' | 'dark';
  addOnClass?: string;
  appearance?: 'outline' | 'filled';
  onClick?: any;
  font?: 400 | 500;
  textColor?: 'dark' | 'white' | 'editor';
  icon?: any;
};

export default function ButtonComponent({
  id,
  text = 'Button',
  type = 'button',
  width = 'fit',
  addOnClass = '',
  appearance = 'filled',
  color = 'primary',
  textColor = 'dark',
  disable,
  onClick,
  size = 'md',
  font = 400,
  icon,
}: ButtonComponentModel) {
  const classList = classNames('ng-button', addOnClass, {
    'w-full': width === 'full',
    'h-[45px]': size === 'md',
    'h-[39px]': size === 'sm',
    'flex gap-x-2': icon,
    'border-solid border-0.5': appearance === 'outline',
    'border-primary-100': appearance === 'outline' && color === 'primary',
    'border-secondary-80': appearance === 'outline' && color === 'secondary',
    'border-editor-primary-100': appearance === 'outline' && color === 'editor',
    'hover:bg-primary-20': !disable && appearance === 'outline' && color === 'primary',
    'hover:bg-secondary-40': !disable && appearance === 'outline' && color === 'secondary',
    'hover:bg-editor-primary-80': !disable && color === 'editor',
    'hover:bg-primary-80': !disable && appearance === 'filled' && color === 'primary',
    'bg-primary-100': !disable && appearance === 'filled' && color === 'primary',
    'bg-editor-primary-100': !disable && appearance === 'filled' && color === 'editor',
    'bg-editor-primary-20': disable && appearance === 'filled' && color === 'editor',
    'bg-secondary-20': !disable && appearance === 'filled' && color === 'secondary',
    'bg-secondary-100': disable && appearance === 'filled' && color === 'secondary',
    'bg-primary-20': disable && appearance === 'filled' && color === 'primary',
    'bg-secondary-40 cursor-not-allowed': disable && appearance === 'outline',
    'bg-surface-75 hover:text-secondary-20': !disable && color == 'dark',
    'text-surface-100': textColor === 'dark' && !disable,
    'text-surface-65': textColor === 'dark' && disable,
    'text-standard-white': textColor === 'white',
    'text-editor-primary-100 hover:text-standard-white': textColor === 'editor',
    'hover:text-surface-100': !disable && appearance === 'outline' && color === 'secondary',
    'font-graphik-regular': font === 400,
    'font-graphik-medium': font === 500,
  });
  return (
    <button id={id} type={type} className={classList} onClick={onClick} disabled={disable}>
      <span>{icon}</span>
      <span>{text}</span>
    </button>
  );
}
