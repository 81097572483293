import axios from 'axios';
import { Config } from '../config';
import { UrlKeys } from '../models/requests.model';

const controller = new AbortController();
/**
 * Request Interceptor
 */
axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
);

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.config.url !== Config.API.login) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error.response);
  }
);

/**
 * This function is to call the GET method using
 * @param urlKey key which refers to key in the Endpoint in Config class
 * @param options Extra option if any to be passed in the axios config
 * @param params Extra params to be passed in the API
 * @returns promise
 */
const get = (urlKey: UrlKeys, options?: any, params = '') => {
  let config = {};
  if (options) config = { ...options };

  const url = Config.API[urlKey] + params;

  return axios.get(url, config);
};

/**
 * This function is to call the POST method using
 * @param urlKey key which refers to key in the Endpoint in Config class
 * @param payload Payload to be passed in the API
 * @param options Extra option if any to be passed in the axios config
 * @returns promise
 */
const post = (urlKey: UrlKeys, payload: any, options?: any) => {
  let config = {};
  if (options) config = { ...options };

  const url = Config.API[urlKey];

  return axios.post(url, payload, config);
};

export const HttpService = {
  getRequest: get,
  postRequest: post,
  SourceController: controller,
};
