import { useState } from 'react';
import { GetIcons } from '../../assets/svg/icons';
import { Button, Modal } from 'antd';
import { Config } from '../../config';
import { HttpService } from '../../service/http.service';
import { ToasterService } from '../../service/toaster.service';
export default function IconCell({ value, data }: any) {
  const [modelShow, setModelShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const preview = () => {
    if (!value.action) {
      ToasterService('error', value?.message || 'Action Not Allowed');
      return;
    }
    setModelShow(true);
  };

  // Download Media
  const downloadMedia = () => {
    try {
      setLoading(true);
      const config = {
        onDownloadProgress: (event: any) => {
          const percentage = Math.round(100 * (event.loaded / event.total));
          // setDownLoadData(percentage);
          if (percentage == 100) {
            // setDownLoadData(0);
          }
        },
        responseType: 'blob',
      };
      HttpService.postRequest(
        'get_file',
        {
          task_id: data?.task_id,
        },
        config
      )
        .then((blob: any) => {
          setLoading(false);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([blob.data]));
          link.download = data?.video_id + '.mp4';
          link.click();
        })
        .catch(e => {
          // setDownLoadData(0);
          setLoading(false);
          ToasterService('error', e?.data?.message || 'Download Failed');
        });
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      <div key={data?.sl_no} onClick={preview} className={value?.class}>
        {GetIcons(value?.icon)}
        <span>{value?.text}</span>
      </div>
      <Modal
        destroyOnClose
        title='Preview'
        width={1000}
        footer={
          value?.type != 'in' && value?.download
            ? [
                <Button
                  type='default'
                  key='donwload'
                  icon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-3 h-3'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3'
                      />
                    </svg>
                  }
                  onClick={() => downloadMedia()}
                  loading={loading}
                  disabled={!data?.output_file}>
                  Download
                </Button>,
              ]
            : null
        }
        open={modelShow}
        onCancel={() => setModelShow(false)}>
        <video
          className='mx-auto max-h-full mb-6'
          controls
          id='stats-preview'
          controlsList='nodownload'>
          <source
            src={`${Config.API.in_video_streaming}${
              value?.type == 'in' ? data?.video_filename : data?.output_file
            }`}
            type='video/mp4'
          />
        </video>
        {value.type == 'in' ? (
          <audio
            className='mx-auto max-h-full w-full mb-6'
            controls
            src={Config.API.in_audio_streaming + data?.audio_filename}
            id='stats-audio-preview'
            controlsList='nodownload'></audio>
        ) : null}
      </Modal>
    </>
  );
}
