import { atom } from 'recoil';

export const Loader = atom({
  key: 'loader',
  default: false,
});

export const MainLoader = atom({
  key: 'main-loader',
  default: false,
});
export const UserDetails = atom({
  key: 'user-details',
  default: {},
});

export const RecentProject = atom({
  key: 'recent-project',
  default: '',
});

export const MetaData = atom({
  key: 'meta-data',
  default: {},
});

export const CurrentFile = atom({
  key: 'current-file',
  default: null,
});

export const SelectedOutput = atom({
  key: 'selected-output',
  default: {},
});
