import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { HttpService } from '../../service/http.service';
import { ToasterService } from '../../service/toaster.service';
import { useSetRecoilState } from 'recoil';
import { Loader } from '../../recoil/atoms';
import { SessionService } from '../../service/session.service';

export default function TopNav() {
  const location = useLocation();
  const mainLoading = useSetRecoilState(Loader);
  const navigate = useNavigate();
  const paths = location.pathname.split('/');
  const endpoint = paths[paths.length - 1];
  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    const data = SessionService.get_decoded_token();
    setUserData(data);
  }, []);
  const logout = () => {
    try {
      HttpService.getRequest('logout')
        .then(res => {
          mainLoading(false);
          if (res) {
            localStorage.clear();
            navigate('/login');
          }
        })
        .catch(e => {
          mainLoading(false);
          ToasterService('error', e?.data?.message || 'Failed to load');
        });
    } catch (e) {
      mainLoading(false);
    }
  };
  return (
    <div className='h-[80px] px-5 flex justify-between items-center'>
      <h3 className='text-secondary-60 text-3xl font-graphik-medium capitalize'>{endpoint}</h3>

      <div className='flex items-center gap-x-4'>
        <Menu as='div' className='relative inline-block text-left z-10'>
          {({ open }) => (
            <>
              <Menu.Button className=' rounded-full focus:outline-none '>
                <img
                  className={`max-w-10 max-h-10 rounded-full cursor-pointer ${
                    open ? 'border-[3px] ring-offset-2 border-editor-primary-80' : ''
                  }`}
                  src={require('../../assets/images/person.png')}
                  alt='Rounded avatar'></img>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'>
                <Menu.Items className='absolute right-1 mt-2 origin-top-right rounded-2xl bg-surface-85 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <div className='px-1 py-1 '>
                    <Menu.Item>
                      {() => (
                        <div className=' divide-y rounded-lg shadow w-44  divide-gray-600'>
                          {userData && userData?.full_name && userData?.email ? (
                            <div className='px-4 py-3 text-sm text-gray-900 dark:text-white'>
                              <div>{userData?.full_name}</div>
                              <div className='font-medium truncate'>{userData?.email}</div>
                            </div>
                          ) : null}

                          <div className='py-2'>
                            <span
                              onClick={logout}
                              className={`block cursor-pointer px-4 py-2 text-sm hover:bg-gray-600 text-secondary-20`}>
                              Sign out
                            </span>
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}
