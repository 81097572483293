import { Button, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthSideBanner from '../../layouts/auth-side-banner/auth-side-banner.layout';
import { SmileOutlined } from '@ant-design/icons';
export default function SuccessMessage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <div className='h-screen flex'>
      <AuthSideBanner />
      <div className='m-auto py-10 px-16'>
        <Result
          icon={<SmileOutlined />}
          title={<span className='text-surface-100'>{state.message || 'Success'}</span>}
          extra={
            <Button
              type='primary'
              className='bg-editor-primary-100'
              onClick={() => {
                navigate('/login', { replace: true });
              }}>
              Login
            </Button>
          }
        />
      </div>
    </div>
  );
}
