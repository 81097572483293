import { Outlet } from 'react-router-dom';
import SideNav from '../../layouts/side-nav/side-nav.layout';
import TopNav from '../../layouts/top-nav/top-nav.layout';
import './main.scss';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
export default function Main() {
  return (
    <div className='flex h-full'>
      <SideNav />
      <div className='w-[calc(100%_-_278px)] h-full bg-surface-100'>
        <TopNav />
        <div className='p-5 h-[calc(100%_-_80px)] overflow-y-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
